import React from 'react'

import styles from './content.module.scss'

const Content = ({ data, html }) => (
  <div className={data.center ? styles.centered : ''} dangerouslySetInnerHTML={{ __html: html }} />
)

const ContentBlock = {
  label: 'Content',
  name: 'content',
  key: 'test',
  defaultItem: {
    content: '',
    center: false
  },
  fields: [{
    name: 'content',
    label: 'Content',
    component: 'markdown'
  }, {
    name: 'center',
    label: 'Center',
    component: 'toggle'
  }]
}

export { Content, ContentBlock }
