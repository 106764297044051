import React from 'react'
import Img from 'gatsby-image'
import get from 'lodash.get'

import styles from './image.module.scss'

const Image = ({ data }) => {
  return (
    data.image &&
    data.image.childImageSharp && (
      <div className={styles.imageWrapper}>
        <Img fluid={data.image.childImageSharp.fluid} />
      </div>
    )
  )
}

const ImageBlock = {
  label: 'Image',
  name: 'image',
  key: 'test',
  defaultItem: {
    image: ''
  },
  fields: [{
    label: 'Image',
    name: 'image',
    component: 'image',
    parse: filename => `../images/${filename}`,
    uploadDir: () => '/content/images/',
    previewSrc: (formValues, fieldProps) => {
      const pathName = fieldProps.input.name.replace('rawJson', 'jsonNode')
      const imageNode = get(formValues, pathName)
      if (!imageNode || !imageNode.childImageSharp) return ''
      return imageNode.childImageSharp.fluid.src
    }
  }]
}

export { Image, ImageBlock }
